import React from 'react';
import Independent from '../../Assets/Independent.png'
import Event from '../../Assets/Event.png'

const WhatWeOffer = () => {
    const offers = [
        {
            id: 1,
            title: 'Independent Ambulance Service',
            image: Independent,
            description: 'Located on the outskirts of Derry~Londonderry, with swift connections to both the North-West and Belfast, JACE Medical provides premier private ambulance services in Northern Ireland. Operating seamlessly seven days a week, 365 days a year, we extend our coverage across all of Northern Ireland, offering private ambulance services, medical repatriation via road and air, as well as comprehensive event medical coverage. Elevating our services, we also present an exclusive VIP option, complete with medical close protection for a truly comprehensive offering.'
        },
        {
            id: 2,
            title: 'Event Medical Cover',
            image: Event,
            description: 'We offer comprehensive support throughout all stages of event planning, ranging from risk assessment and resource allocation to debriefing and reporting. Our primary objective is to assist event organisers in fulfilling their duty of care to all event attendees. Our services encompass medical coverage for a wide array of events, including sports and music gatherings, charity fundraisers, film and TV productions, and much more. Additionally, we provide the option of enhanced care teams for those seeking an elevated level of assistance.'
        }
    ];

    return (
        <div id='What we offer' className="flex flex-col items-center p-8 bg-[#f0f5f5]">
            <h1 className='font-semibold mb-2 text-2xl md:text-3xl text-[#3a313e]'>What we offer</h1>
            <div className="h-1 mt-2 mb-8 bg-[#56bcbe] w-1/3 md:w-2/12"></div>

            <div className="grid gap-8 md:grid-cols-2 items-stretch w-full max-w-screen-lg">
                {offers.map(offer => (
                    <div key={offer.id} className="border bg-white shadow-lg p-6 rounded-lg text-center flex flex-col h-full">
                        <img width={80} height={80} src={offer.image} alt={offer.title} className="mx-auto mb-4 border border-1 border-[#56bcbe] px-2 py-2 rounded-full" />
                        <h3 className="text-2xl font-semibold mb-2 text-[#3a313e]">{offer.title}</h3>
                        <p className="text-[#3a313e] text-lg md:text-left">{offer.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhatWeOffer;
