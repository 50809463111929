import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { LoadScript } from 'google-maps-react';

const RequestService = () => {

    const [dob, setDob] = useState('')
    const [name, setName] = useState('')
    const [refName, setRefName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [emailaddress, setEmailAddress] = useState('')
    const [medicalStatus, setMedicalStatus] = useState('')
    const [isInfected, setIsInfected] = useState('')
    const [pickupLocation, setPickupLocation] = useState('')
    const [dropOffLocation, setDropOffLocation] = useState('')
    const [otherLocation, setOtherLocation] = useState('')
    const [otherDropOffLocation, setotherDropOffLocation] = useState('')
    const [hospitalPickUpLocation, setHospitalPickUpLocation] = useState('')
    const [hospitalDropoffLocation, setHospitalDropoffLocation] = useState('')
    const [dateTimePickup, setDateTimePickup] = useState('')
    const [covid, setIsCovid] = useState('')
    const [medicalEscort, setmedicalEscort] = useState('')
    const [mobility, setmobility] = useState('');
    const [returnjourney, setReturnjourney] = useState('');
    const [hasSepcialRequirements, setHassepcialRequirements] = useState('');
    const [sepcialRequirements, setsepcialRequirements] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const formattedValue = inputValue.replace(/\D/g, '');

        if (formattedValue.length <= 13) {
            setPhoneNumber(formattedValue);
        }
    };

    const history = useHistory();

    const handleNavigation = () => {
        history.push('/request-success');
    };

    const handleSelectPickup = async (address) => {
        setOtherLocation(address);
        try {
          const results = await geocodeByAddress(address);
          const latLng = await getLatLng(results[0]);
          console.log('Geocode successful', latLng);
        } catch (error) {
          console.error('Error in geocoding', error);
        }
      };
    
      const handleSelectDropOff = async (address) => {
        setotherDropOffLocation(address);
        try {
          const results = await geocodeByAddress(address);
          const latLng = await getLatLng(results[0]);
          console.log('Geocode successful', latLng);
        } catch (error) {
          console.error('Error in geocoding', error);
        }
      };

      const validateForm = () => {
        if (

            refName &&
            phoneNumber &&
            emailaddress &&
            name &&
            dob &&
            isInfected &&
            pickupLocation &&
            dateTimePickup &&
            dropOffLocation &&
            returnjourney &&
            medicalEscort &&
            mobility
        ) {
            if (isInfected === 'yes' && !covid) {
                setError('Please specify if the infection is COVID.');
                return false;
            }

            if (hasSepcialRequirements === 'yes' && !sepcialRequirements) {
                setError('Please specify the sepcial requirements.');
                return false;
            }

            if (pickupLocation === 'Other' && !otherLocation) {
                setError('Please enter the pickup address.');
                return false;
            }

            if (dropOffLocation === 'Other' && !otherDropOffLocation) {
                setError('Please enter the drop-off address.');
                return false;
            }
            return true;
        }
        setError('Please fill in all required fields.');
        return false;
    };

    const handleServiceRequest = async () => {
        try {

            setLoading(true)
            setError('');

            if (!validateForm()) {
                setLoading(false);
                return;
            }

            const response = await fetch('https://jace-medical-ambulance-api.vercel.app/api/services/CreateRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    refName,
                    returnjourney,
                    dob,
                    phoneNumber,
                    emailaddress,
                    isInfected,
                    covid,
                    pickupLocation,
                    otherLocation,
                    dateTimePickup,
                    dropOffLocation,
                    otherDropOffLocation,
                    medicalEscort,
                    mobility,
                    sepcialRequirements,
                    hospitalPickUpLocation,
                    hospitalDropoffLocation
                }),
            });

            const responseBody = await response.json();

            if (responseBody.success === true) {
                setLoading(false);
                handleNavigation();
            }
            if (!response.ok) {
                setLoading(false)
                throw new Error('Error submitting service request');
            }

        } catch (error) {
            setLoading(false)
            setError('Error submitting service request: ' + error.message);
        }
    };

    return (
        <>
            <div className="px-6 my-8">
                <p className='font-bold text-2xl'>Referrer Details</p>

                <div className="mb-4 mt-4">
                    <label htmlFor="name" className="block font-bold mb-2">Referrer Name:</label>
                    <input
                        value={refName}
                        onChange={(e) => setRefName(e.target.value)}
                        type="text"
                        id="name"
                        name="name"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="phone" className="block font-bold mb-2">Referrer Phone Number:</label>
                    <input
                        value={phoneNumber}
                        onChange={(e) => handlePhoneChange(e)}
                        type="tel"
                        id="phone"
                        name="phone"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block font-bold mb-2">Referrer Email:</label>
                    <input
                        value={emailaddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        type="text"
                        id="email"
                        name="email"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>

                <p className='font-bold text-2xl'>Patient Details</p>

                <div className="mb-4 mt-4">
                    <label htmlFor="name" className="block font-bold mb-2">Patient Name:</label>
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        id="name"
                        name="name"
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="dob" className="block font-bold mb-2">Patient DOB:</label>
                    <input
                        type="date"
                        id="dob"
                        name="dob"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>

               
                <div className="mb-4">
                    <label htmlFor="infectedStatus" className="block font-bold mb-2">Is Patient Infected:</label>
                    <select
                        value={isInfected}
                        onChange={(e) => setIsInfected(e.target.value)}
                        id="infectedStatus"
                        name="infectedStatus"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>

                {isInfected === 'yes' && (
                    <div className="mb-4">
                        <label htmlFor="covidStatus" className="block font-bold mb-2">
                            Is Infection Covid:
                        </label>
                        <select
                            value={covid}
                            onChange={(e) => setIsCovid(e.target.value)}
                            id="covidStatus"
                            name="covidStatus"
                            className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        >
                            <option value=""></option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                )}

                <p className='font-bold text-2xl mb-4'>Journey Details</p>

                <div className="mb-4">
                    <label htmlFor="pickupAddress" className="block font-bold mb-2">Pickup Address:</label>
                    <select
                        value={pickupLocation}
                        onChange={(e) => setPickupLocation(e.target.value)}
                        id="pickupAddress"
                        name="pickupAddress"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="Causeway Hospital">Causeway Hospital</option>
                        <option value="Antrim Hospital">Antrim Hospital</option>
                        <option value="Altnagelvin Hospital">Altnagelvin Hospital</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                {pickupLocation !== 'Other' && (
                    <div className="mb-4">
                        <label htmlFor="additionalDropdown" className="block font-bold mb-2">Hospital Pickup Location:</label>
                        <select
                            value={hospitalPickUpLocation}
                            onChange={(e) => setHospitalPickUpLocation(e.target.value)}
                            id="additionalDropdown"
                            name="additionalDropdown"
                            className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        >
                            <option value=""></option>
                            <option value="A&E">A&E</option>
                            <option value="Surgical 1">Surgical 1</option>
                            <option value="Surgical 2">Surgical 2</option>
                            <option value="Medical 1">Medical 1</option>
                            <option value="Medical 2">Medical 2</option>
                            <option value="Discharge Lounge">Discharge Lounge</option>
                            <option value="CCU">CCU</option>
                            <option value="Wards 21/22/23/24/25">Wards 21/22/23/24/25</option>
                        </select>
                    </div>
                )}
                {pickupLocation === 'Other' && (
                    <div className="mb-4 mt-4">
                        <label htmlFor="otherLocation" className="block font-bold mb-2">Please enter the address:</label>
                        <PlacesAutocomplete
                            value={otherLocation}
                            onChange={(address) => setOtherLocation(address)}
                            onSelect={handleSelectPickup}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Type your pickup address...',
                                            className:
                                                'w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400',
                                        })}
                                    />
                                    <div>
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                            const style = {
                                                backgroundColor: suggestion.active ? '#2dd4bf' : '#fff',
                                                padding: 10,
                                                border: 1,
                                            };
                                            return (
                                                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                )}

                <div className="mb-4">
                    <label htmlFor="dateTimePickup" className="block font-bold mb-2">Date /Time Of Pickup:</label>
                    <DateTimePicker

                        id="dateTimePickup"
                        name="dateTimePickup"
                        onChange={setDateTimePickup}
                        value={dateTimePickup}
                        className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="dropOffAddress" className="block font-bold mb-2">Drop Off Address:</label>
                    <select
                        value={dropOffLocation}
                        onChange={(e) => setDropOffLocation(e.target.value)}
                        id="dropOffAddress"
                        name="dropOffAddress"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="Causeway Hospital">Causeway Hospital</option>
                        <option value="Antrim Hospital">Antrim Hospital</option>
                        <option value="Altnagelvin Hospital">Altnagelvin Hospital</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                {dropOffLocation !== 'Other' && (
                    <div className="mb-4">
                        <label htmlFor="additionalDropdown" className="block font-bold mb-2">Hospital Pickup Location:</label>
                        <select
                            value={hospitalDropoffLocation}
                            onChange={(e) => setHospitalDropoffLocation(e.target.value)}
                            id="additionalDropdown"
                            name="additionalDropdown"
                            className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        >
                            <option value=""></option>
                            <option value="A&E">A&E</option>
                            <option value="Surgical 1">Surgical 1</option>
                            <option value="Surgical 2">Surgical 2</option>
                            <option value="Medical 1">Medical 1</option>
                            <option value="Medical 2">Medical 2</option>
                            <option value="Discharge Lounge">Discharge Lounge</option>
                            <option value="CCU">CCU</option>
                            <option value="Wards 21/22/23/24/25">Wards 21/22/23/24/25</option>
                        </select>
                    </div>
                )}

                {dropOffLocation === 'Other' && (
                    <div className="mb-4 mt-4">
                        <label htmlFor="otherDropoffLocation" className="block font-bold mb-2">Please enter the address:</label>
                        <PlacesAutocomplete
                            value={otherDropOffLocation}
                            onChange={(address) => setotherDropOffLocation(address)}
                            onSelect={handleSelectDropOff}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Type your pickup address...',
                                            className:
                                                'w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400',
                                        })}
                                    />
                                    <div>
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                            const style = {
                                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                            };
                                            return (
                                                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                )}

                <div className="mb-4">
                    <label htmlFor="covidStatus" className="block font-bold mb-2">
                        Return Journey Needed:
                    </label>
                    <select
                        value={returnjourney}
                        onChange={(e) => setReturnjourney(e.target.value)}
                        id="covidStatus"
                        name="covidStatus"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label htmlFor="medicalEscort" className="block font-bold mb-2">Medical Escort Required:</label>
                    <select
                        value={medicalEscort}
                        onChange={(e) => setmedicalEscort(e.target.value)}
                        id="medicalEscort"
                        name="medicalEscort"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="mobility" className="block font-bold mb-2">Mobility:</label>
                    <select
                        value={mobility}
                        onChange={(e) => setmobility(e.target.value)}
                        id="mobility"
                        name="mobility"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="Walking">Walking</option>
                        <option value="Chair to ambulance">Chair to ambulance</option>
                        <option value="Stretcher">Stretcher</option>
                    </select>
                </div>

                <div className="mb-4 mt-4">
                    <label htmlFor="specialRequirements" className="block font-bold mb-2">Special Requirements:</label>
                    <select
                        value={hasSepcialRequirements}
                        onChange={(e) => setHassepcialRequirements(e.target.value)}
                        id="specialRequirements"
                        name="medicalspecialRequirementsEscort"
                        className="w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                    >
                        <option value=""></option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>

                {hasSepcialRequirements === 'yes' && (
                    <div className="mb-4 mt-4">
                        <label htmlFor="otherDropoffLocation" className="block font-bold mb-2">Special Requirements:</label>
                        <input
                            value={sepcialRequirements}
                            onChange={(e) => setsepcialRequirements(e.target.value)}
                            type="text"
                            id="otherDropoffLocation"
                            name="otherDropoffLocation"
                            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                        />
                    </div>
                )}

                {error && <div className="text-red-500 mb-4">{error}</div>} {/* Render error message if it exists */}
                <button
                    onClick={() => handleServiceRequest()}
                    disabled={loading}
                    className={`rounded-md w-full px-3 py-2.5 text-sm font-semibold leading-6 shadow-sm 
                ${loading ? 'bg-gray-300 text-gray-600 cursor-not-allowed' : 'bg-teal-400 text-white hover:bg-teal-500'} 
                mr-4`}
                >
                    {loading ? 'Submitting...' : 'Submit Request'}
                </button>
            </div>
        </>
    )

}

export default RequestService