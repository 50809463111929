import React from 'react';
import { GiCheckMark } from 'react-icons/gi';

const OurServices = () => {
    const services = [
        "Independent Ambulance Service",
        "Event Medical Cover",
        "Non-Emergency Patient Transport",
        "Enhanced Care",
        "HDU / Urgent Care",
        "Repatriation"
    ];

    return (
        <div id='Our Services' className="flex flex-col items-center p-8 bg-[#f9f4f1]">
            <h1 className='font-semibold text-[#3a313e] text-2xl md:text-3xl mb-4 relative'>
                Our Range of Services
            </h1>
            <div className="h-1 mt-2 mb-6 bg-[#56bcbe] w-1/2 md:w-1/4"></div>
            <ul className="list-none pt-6 m-0 text-left grid grid-cols-1 md:grid-cols-2 gap-4"> 
                {services.map((service, index) => (
                    <li key={index} className="flex text-lg text-[#3a313e] md:text-xl items-center">
                        <div className="bg-[#56bcbe] w-10 h-10 py-2 px-2 mr-4 flex items-center justify-center rounded-full">
                           <GiCheckMark size={30} color='white'/>
                        </div>
                        {service}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default OurServices;
