import Logo from '../Assets/Jace-white.png'

const Footer = () => {
    return (

        <footer class="shadow bg-[#39313e] w-full">
            <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0">
                        <img src={Logo} class="h-4 mr-3 " alt="Jace Medical Logo" />
                    </a>
                    <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 ">
                        <li>
                            <a href="https://www.facebook.com/jacemedic/" class="mr-4 hover:underline md:mr-6 ">Facebook</a>
                        </li>
                        <li>
                            <a href="https://twitter.com/jacemedical?s=21&t=lcfj0AFVBVJrltuhG2K46Q" class="mr-4 hover:underline md:mr-6">Twitter</a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/jacemedical/" class="mr-4 hover:underline md:mr-6 ">Instagram</a>
                        </li>
                        <li>
                            <a href="https://uk.linkedin.com/company/jacemedical" class="hover:underline">LinkedIn</a>
                        </li>
                    </ul>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
                <span class="block text-sm sm:text-center text-white">© 2023 <a href="" class="hover:underline">Jace Medical™</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export default Footer