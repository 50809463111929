import React from 'react';
import Medic from '../../Assets/Medic.jpg';

const OurMission = () => {
    return (
        <div id='Why us' className="flex flex-col items-center p-8">
            <div className="flex flex-col md:flex-row md:items-start items-center mb-4">
                <img src={Medic} alt="Image" className="max-w-full h-auto md:w-1/2 rounded mb-4 md:mb-0" />
                <div className="md:w-1/2 md:pl-8">
                    <h2 className="font-semibold text-[#3a313e] md:text-3xl text-2xl mb-2">Patient-focused & comprehensive care</h2>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        What truly sets JACE Medical apart is our unwavering dedication to patient-focused care. Our expertise shines whether it’s a small gathering or a large-scale event. Our collaboration with you on meticulous planning, debriefing, and comprehensive reporting ensures that attendee well-being is at the forefront of every decision.
                    </p>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        Our team consists of highly trained medical professionals who prioritize patient needs above all else. From command and control operations to delivering on-the-ground care, our professionals are well-versed and ready to provide assistance. We custom-build teams based on your event’s unique requirements, drawing from a pool of experts that include doctors, paramedics, emergency nurses, ambulance technicians, and skilled first aiders.
                    </p>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        Our team consists of highly trained medical professionals who prioritise patient needs above all else. From command and control operations to delivering on-the-ground care, our professionals are well-versed and ready to provide assistance. We custom-build teams based on your event’s unique requirements, drawing from a pool of experts that include doctors, paramedics, emergency nurses, ambulance technicians, and skilled first aiders.            </p>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        JACE Medical’s reputation is built on our exceptional patient-focused care. As the preferred medical service provider for leading companies and events in Ireland, we’ve proven time and again that our dedication to professionalism is unwavering.            </p>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        Our medical personnel carry cutting-edge emergency equipment, ensuring we’re always ready to respond to any situation. From remote locations to challenging environments, our response cars and ambulances are equipped to handle it all. With medical tents complementing our services, we’re prepared to set up field hospitals when needed, further demonstrating our commitment to comprehensive care.            </p>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        Whether you’re hosting an event or in need of reliable ambulance transfers, our patient-focused approach, expert team, and state-of-the-art equipment are at your service.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OurMission;


