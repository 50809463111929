import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const FAQSection = () => {
    const faq = [
        {
            id: 1,
            question: 'What is the cost of private ambulance transfer?',
            answer: 'Each quotation is bespoke depending on location of collection, the destination and time required. Contact us by clicking here or call us on +44 (0) 333 40 41 999 now for a quotation.'
        },
        {
            id: 2,
            question: 'Which areas do you cover?',
            answer: 'We cover the whole of Northern Ireland and offer event medical cover and repatriation across the island.'
        },
        {
            id: 3,
            question: 'Do I need to escort my family member?',
            answer: 'You are more than welcome to have someone if you would like to. You will have dedicated ambulance personnel on board throughout the journey to assist you with anything you require during the journey.'
        },
        {
            id: 4,
            question: 'Do you stop off for long journeys for toileting and lunch/dinner?',
            answer: 'Yes we do if the patient requires food or to use the toilet.'
        },
        {
            id: 5,
            question: 'Can you arrange with the hospitals the pickup times and discharge date?',
            answer: 'Yes we can liaise with hospitals and care homes to make the process as easy as possible for you.'
        },
        {
            id: 6,
            question: 'What other information do I need?',
            answer: 'We need information on the patient’s details including some medical information. In addition we need the pick-up address along with an understanding of the access and an understanding of the patients needs.'
        },
        {
            id: 7,
            question: 'Can I take personal belongings?',
            answer: 'You will be able to take a personal bag and suitcase if required. If you need additional items please advise us when making your booking.'
        },
        {
            id: 8,
            question: 'Do you provide refreshments for long transfers?',
            answer: 'Yes, we have a range of refreshments onboard.'
        },
        {
            id: 9,
            question: 'How quickly can you arrange an ambulance?',
            answer: 'We can offer same day transfers for private and ambulance hire and for hospitals.'
        },
        {
            id: 10,
            question: 'Will my private ambulance have air conditioning and heating?',
            answer: 'Yes, we keep our ambulances at a comfortable temperature during the transfer.'
        },
        {
            id: 11,
            question: 'What if the patient requires collection from an upstairs area or somewhere which isnt easy to access?',
            answer: 'We aim to facilitate your transfer in a safe and comfortable manner for you. Advise us of any requirements when you book and we can supply additional staff or equipment to aid the process.'
        }
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedFAQ, setSelectedFAQ] = useState(null);

    const toggleFAQ = (id) => {
        if (selectedFAQ === id) {
            setIsOpen(false);
            setSelectedFAQ(null);
        } else {
            setIsOpen(true);
            setSelectedFAQ(id);
        }
    };

    return (
        <div id='FAQ' className="flex flex-col items-center p-8">
            <h2 className="font-semibold text-2xl mb-1">FAQ</h2>
            <div className="h-1 mt-2 mb-8 bg-[#56bcbe] w-1/6"></div>

            <div className="text-left max-w-lg">
                {faq.map(item => (
                    <div onClick={() => toggleFAQ(item.id)} key={item.id} className="mb-4 p-4 bg-gray-100 rounded-lg">
                        <div className="flex items-center justify-between">
                            <h3 className="font-medium mb-2 max-w-[75%] text-[#3a313e]">{item.question}</h3>
                            <div
                                className="bg-[#56bcbe] text-white rounded-full w-8 h-8 flex items-center justify-center cursor-pointer"
                                onClick={() => toggleFAQ(item.id)}
                            >
                                {selectedFAQ === item.id ? <AiOutlineMinus/> : <AiOutlinePlus/>}
                            </div>
                        </div>
                        {selectedFAQ === item.id && <p className="text-gray-700">{item.answer}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
