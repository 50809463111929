import React from 'react';
import HeroImage from '../../Assets/ambulance.jpg'
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

Modal.setAppElement('#root');

const Hero = ({ openModal }) => {
  const backgroundImage = 'url(' + HeroImage + ')';
  const history = useHistory();

  const handleBookClick = () => {
    history.push('/service-request');
  };

  return (
    <div className="relative bg-cover bg-center p-8 md:p-24 text-white" style={{ backgroundImage }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="text-center relative z-10 mb-4">
        <h1 className="text-lg md:text-3xl font-bold mb-2">Patient Transfers & Event Hire</h1>
        <p className="text-4xl md:text-6xl font-bold">Private Ambulance Northern Ireland</p>
      </div>
      <div className="flex flex-col items-center z-10">
        <button className="bg-[#56bcbe] z-10 text-white font-bold py-2 px-4 md:py-3 md:px-6 mb-2 rounded w-3/4 md:w-1/4" onClick={() => window.location.href = "tel:02871165790"}>
          Call us
        </button>
        <button onClick={handleBookClick} className="bg-[#56bcbe] z-10 text-white font-bold py-2 px-4 md:py-3 md:px-6 rounded w-3/4 md:w-1/4">Book NEPTS</button>
      </div>
    </div>
  );
};

export default Hero;
