import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AmbulanceHome from './Screens/AmbulanceHome';
import RequestService from './Screens/RequestService';
import NavigationBar from './Components/NavigationBar';
import SuccessPage from './Screens/SuccessPage';
import Footer from './Components/Footer';

function App() {
  return (
    <Router forceRefresh={true}>
      <div>
        <NavigationBar/>
        <Switch>
        <Route exact path="/">
            <AmbulanceHome />
          </Route>
          <Route exact path="/service-request">
            <RequestService />
          </Route>
          <Route exact path="/request-success">
            <SuccessPage />
          </Route>
        </Switch>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
