import React from 'react';
import Ambulance from '../../Assets/ambulance2.jpg';

const InfoComponent = () => {
    return (
        <div id='aboutUs' className="bg-white p-8 shadow-lg rounded-lg text-center">
            <div className="flex flex-col md:flex-row items-start">
                <img
                    src={Ambulance}
                    alt="info"
                    className="w-full md:w-1/3 h-auto rounded mb-4 md:mb-0 md:mr-16"
                />
                <div className="md:w-1/2 text-left">
                    <h2 className="text-xl md:text-2xl font-bold mb-2 text-[#3a313e]">
                        The New Standard in Ambulance Transfers
                    </h2>
                    <h2 className="text-2xl md:text-3xl font-bold mb-2 text-[#3a313e]">
                        Private Ambulance Northern Ireland
                    </h2>
                    <p className="text-[#3a313e] text-lg md:text-xl mb-4">
                        At JACE Medical, we’re not just another ambulance service; we’re setting a new standard for ambulance transfers in Northern Ireland. As a proud member of the Independent Ambulance Association, we bring a fresh approach to medical transport. Our commitment to excellence is evident in our state-of-the-art fleet of ambulances, each meticulously equipped to meet diverse patient needs.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default InfoComponent;
