import React from 'react';
import { GiCheckMark } from 'react-icons/gi';

const WeHaveYouCovered = () => {
    const services = [
        "Event Medical Cover",
        "Charity Fundraising Events",
        "Sporting Events",
        "Film/TV Medical Cover",
        "Music Events / Festivals",
        "Enhanced Care Teams"
    ];

    return (
        <div className="flex flex-col items-center p-8 bg-[#f9f4f1]">
            <h1 className='max-w-lg text-[#3a313e] font-semibold text-2xl md:text-3xl mb-2 relative text-center'>
                From the planning stages of your event to debriefing and reporting, we have you covered
            </h1>
            <div className="h-1 mt-2 mb-6 bg-[#56bcbe] w-1/2 md:w-1/3"></div>
            <ul className="list-none pt-4 m-0 grid grid-cols-1 md:grid-cols-2 gap-4">
                {services.map((service, index) => (
                    <li key={index} className="flex text-[#3a313e] text-lg md:text-xl items-center">
                        <div className="bg-[#56bcbe] w-10 h-10 py-2 px-2 mr-4 flex items-center justify-center rounded-full">
                            <GiCheckMark size={30} color='white' />
                        </div>
                        {service}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default WeHaveYouCovered;
