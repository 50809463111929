import React from 'react'
import InfoComponent from "../Components/Home/AboutUs"
import Hero from "../Components/Home/Hero"
import CustomModal from "../Components/Home/Modal";
import WhatWeOffer from '../Components/Home/WhatWeOffer';
import OurCommitment from '../Components/Home/OurCommitment';
import OurServices from '../Components/Home/OurServices';
import OurMission from '../Components/Home/OurMission';
import WeHaveYouCovered from '../Components/Home/WeHaveYouCovered';
import FAQSection from '../Components/Home/FAQ';

const AmbulanceHome = () => {

    return(
        <>
            <Hero/>
            <InfoComponent/>
            <WhatWeOffer/>
            <OurCommitment/>
            <OurServices/>
            <OurMission/>
            <WeHaveYouCovered/>
            <FAQSection/>
        </>
    )
}

export default AmbulanceHome
