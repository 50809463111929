import React from 'react';
import Image from '../../Assets/Women.jpg'

const OurCommitment = () => {
    return (
        <div id='Our Commitment' className="flex flex-col items-center p-8 text-[#3a313e]">
            <div className="max-w-screen-lg flex flex-col md:flex-row items-start">
                <div className="md:w-1/2 md:pr-8">
                    <h1 className='font-semibold mb-4 text-2xl md:text-3xl'>
                        Unparalleled expertise and cutting-edge equipment
                    </h1>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        Our commitment to providing high quality medical services extends beyond our fleet. JACE Medical boasts a team of dedicated paramedics, EMTs, and ACAs who possess the highest level of training. With comprehensive insurance coverage and a wealth of experience, we’re ready to tackle any pre-hospital emergency with precision and care.
                    </p>
                    <p className="text-[#3a313e] mb-4 text-lg">
                        Our fleet of state-of-the-art ambulances is a testament to our preparedness. These vehicles are outfitted with advanced medical equipment, ensuring that our team is well-equipped for any scenario. From minor injuries to substance-related incidents, we’re prepared to handle it all.
                    </p>
                </div>
                <div className="md:w-1/2">
                    <img src={Image} alt="Our Commitment Image" className="rounded max-w-full h-auto mb-4" />
                </div>
            </div>
        </div>
    );
};

export default OurCommitment;
