import React, { useState } from 'react';
import ReactModal from 'react-modal';
import '../../modal.css';
import GenerateTest from '../../Controllers/createTest';

ReactModal.setAppElement('#root');
const CustomModal = ({ isOpen, closeModal }) => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [error, setError] = useState('');
    const [serviceType, setServiceType] = useState('');

    const ServiceQuestions = ({ serviceType }) => {
        if (serviceType === 'OUTPATIENT') {
          return (
            <div>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Pickup Address:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Appointment Date & Time:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Hospital & Dept:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Approx appt length:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Mobility:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Escort:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Medical Conditions:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>
                <select
                    onChange={() => null}
                    value={serviceType}
                    id="serviceType"
                    name="serviceType"
                    className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                >
                    <option value="NO_OXYGEN">No</option>
                    <option value="YES_OXYGEN">Yes</option>
                    
                </select>
            </div>
          );
        } else if (serviceType === 'DISCHARGE') {
          return (
            <div>
            </div>
          );
        } else if (serviceType === 'H2H_TRANSFER') {
          return (
            <div>
            </div>
          );
        } else if (serviceType === 'ADMISSION') {
          return (
            <div>
            </div>
          );
        }
      
        return null;
      }


    const handleServiceType = (event) => {
        setServiceType(event.target.value);
    };

    const handleCreateTest = async (email, firstName, lastName) => {
        const Name = `${firstName} ${lastName}`
        setError('')

        if (!email || !firstName || !lastName) {
            setError('Please ensure all fields have been filled.');
            return;
        }
        try {
            const createdItem = await GenerateTest(email, Name);
            console.log(createdItem)
        } catch (error) {
            setError(error.message)
        }
    };

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="modal-content"
            overlayClassName="modal-overlay"
        >
            <div className="bg-white p-8 rounded">

                <label htmlFor="serviceType" className="block font-bold mb-2">Service Type:</label>
                <select
                    onChange={handleServiceType}
                    value={serviceType}
                    id="serviceType"
                    name="serviceType"
                    className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
                >
                    <option value="OUTPATIENT">Outpatient</option>
                    <option value="DISCHARGE">Discharge</option>
                    <option value="H2H_TRANSFER">H2H Transfer</option>
                    <option value="ADMISSION">Admission</option>
                </select>

                <div className="mb-4 mt-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Patient Name:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>

                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Patient Address:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>

                <div className="mb-4">
                    <label htmlFor="firstName" className="block font-bold mb-2">Patient DOB:</label>
                    <input onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" name="firstName" className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400" />
                </div>

                <ServiceQuestions serviceType={serviceType} />

                {error && <div className="text-red-500 mb-4">{error}</div>} {/* Render error message if it exists */}
                <button onClick={() => handleCreateTest(email, firstName, lastName)} className="rounded-md bg-teal-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-teal-500 mr-4">Create Test</button>
                <button onClick={closeModal} className="rounded-md border border-teal-400 text-teal-400 bg-white px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:border-teal-500 hover:text-teal-500">Close</button>
            </div>
        </ReactModal >
    );
}

export default CustomModal;
