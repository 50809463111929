import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Logo from '../Assets/JaceLOGO2.png'
const SuccessPage = () => {

    const history = useHistory();

    const handleNavigation = () => {
        history.push('/');
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <img src={Logo} className='w-40'/>
            <h1 className="text-3xl font-bold text-green-500 mt-4">Success</h1>
            <p className="text-center mt-4">Your request has been submitted successfully.</p>
            <button onClick={handleNavigation} className="bg-[#56bcbe] text-white font-bold py-2 px-4 mt-8 rounded">Return Home</button>
        </div>
    );
};

export default SuccessPage;
